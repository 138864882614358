<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="title" label="卡券名称" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="fee" label="卡券面值" show-overflow-tooltip></el-table-column>
      <el-table-column prop="total_use" label="可用次数" show-overflow-tooltip></el-table-column>
      <el-table-column prop="use_limit" label="使用间隔" show-overflow-tooltip :formatter="e=>e.use_limit + (e.use_limit_type === 1 ? '时' : '天')"></el-table-column>
      <el-table-column label="操作" show-overflow-tooltip>
        <template slot="header">
          <el-button @click="$router.push({
          name:'card-merchant-coupon-edit',
          query:{
            ...$route.query
          }
          })">添加卡券</el-button>
        </template>
        <template v-slot="s">
          <el-button @click="$router.push({
          name:'card-merchant-coupon-edit',
          query:{
            id:s.row.id,
            ...$route.query
          }
          })" type="primary">编辑</el-button>
          <el-button @click="del(s.row)" type="info">删除</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: "coupon",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      editBox: false,
      editForm: {},
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    del({id}){
      this.$u.api.card.merchantCouponDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$u.api.card.merchantCouponSearch({
        page: this.page,
        merchant_id:parseInt(this.$route.query.merchant_id)
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>
