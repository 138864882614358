var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticStyle:{"min-height":"45rem"}},[_c('el-table',{attrs:{"data":_vm.list},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('el-pagination',{staticStyle:{"padding":".5rem"},attrs:{"background":"","layout":"total,prev, pager,next","total":_vm.total,"page-size":15,"current-page":_vm.page},on:{"current-change":_vm.pageChange}})]},proxy:true}])},[_c('el-table-column',{attrs:{"prop":"id","label":"编号","width":"100","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"title","label":"卡券名称","width":"100","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"fee","label":"卡券面值","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"total_use","label":"可用次数","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"use_limit","label":"使用间隔","show-overflow-tooltip":"","formatter":e=>e.use_limit + (e.use_limit_type === 1 ? '时' : '天')}}),_c('el-table-column',{attrs:{"label":"操作","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
        name:'card-merchant-coupon-edit',
        query:{
          id:s.row.id,
          ..._vm.$route.query
        }
        })}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.del(s.row)}}},[_vm._v("删除")])]}}])},[_c('template',{slot:"header"},[_c('el-button',{on:{"click":function($event){return _vm.$router.push({
        name:'card-merchant-coupon-edit',
        query:{
          ..._vm.$route.query
        }
        })}}},[_vm._v("添加卡券")])],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }